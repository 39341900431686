import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { EntityLinks } from './types'
import config from '../../../config'
import { ILog } from '../../user-actions/actions/types'

export const getDifferentHoursFromUtcTime = () => {
  const utcTime = moment.utc().format('HH') // todays utc date
  const localTime = moment().format('HH')   // today local time
  const difference = Number(localTime) - Number(utcTime)

  return difference
}

export const getAccessStatusDate = (date: Date, previous: string = '', posterior: string = '') => {
  if (!date) return null
  const diff = getDifferentHoursFromUtcTime();
  const dateExpires = moment(date).subtract(diff, "hours")
 
  if (dateExpires.diff(moment(), 'year') < 100) {
    return `${previous}${dateExpires.format('DD.MM.YYYY')}${posterior}`
  }
  return null
}

export const SHOW_ADS_MIN_ITEMS = 5;

export const capitalize = (string) => {
  if (!string) {
    return '';
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const inputMaxLengthRulesEditEmployee = {
  phone1MaxLength: 20,
  phone2MaxLength: 20,
  skypeMaxLength: 100,
  viberMaxLength: 100,
  emailMaxLength: 50,
}

export const dateFormatter = (date, isShort?: boolean) => {
  if (isShort) {
    return moment(date).format('DD.MM')
  } else {
    return moment(date).format('DD.MM.YYYY')
  }
}

export const todayDateFormatter = (date) => {
  const today = moment(new Date()).format('DD/MM/YY');
  const itemData = moment(date).format('DD/MM/YY');
  const format = today === itemData ? 'HH:mm DD/MM/YY' : 'DD/MM/YY';
  const updatedDate = moment(date).format(format);

  return updatedDate
}

export const directorId = 460;

export const dateTimeFormatter = (date) => {
  return moment(date).format('DD.MM.YYYY HH:mm')
}

export const commentWordEndingMulti = 'иев';
export const commentWordEndingSingle = 'ий';
export const commentWordEndingSingleOne = 'ия';


const entityLinks: EntityLinks = [
  { activeLink: true, valueKey: 'value1', linkKey: 'value2', path: `/user/details/` },
  { activeLink: false },
  { activeLink: true, valueKey: 'value2', linkKey: 'value3', path: '/user/details/' },
  { activeLink: true, valueKey: 'value2', linkKey: 'value3', path: '/user/details/' },
  { activeLink: true, valueKey: 'value1', linkKey: 'value2', path: '/company/item/' },
  { activeLink: false },
  { activeLink: false },
  { activeLink: true, valueKey: 'value2', linkKey: 'value3', path: '/user/details/' },
]

export const addMessageLink = (log) => {
  const { values, message, entityId } = log
  const { activeLink, valueKey, linkKey, path } = entityLinks[entityId - 1]
  if (activeLink) {
    const link = path + values[linkKey]
    const value = values[valueKey]
    const messageArr = message.split(value)

    return (
      <span>
        <span>{messageArr[0]}</span>
        <Link to={link}>{value}</Link>
        <span>{messageArr[2] || null}</span>
      </span>
    )
  }
  return message
}

const renderLinkPart = (text, link, index) => {
  return (
    <Fragment key={index}>
      <span>{text}</span>
      {link && (
        <a href={link} target="_blank" rel="noopener noreferrer">
          {link}
        </a>
      )}
    </Fragment>
  )
}

export const makeLinksActive = text => {
  const linkPattern = /https?:\/\/[\w-]{1,32}\.[\w-]{1,32}[^\s@]*/g
  const links = text.match(linkPattern) || []

  if (!links.length) return text

  const strings = text.split(linkPattern)
  return strings.map((string: string, index: number) => {
    const link = links.shift()
    return renderLinkPart(string, link, index)
  })
}

export const convertImageUrlUuid = (text, imageFolder) => {
  const regExp = /<img src="([^"]*)">/g // img tags
  const imagesSrc = [...text.matchAll(regExp)].map(array => array[1]) // get images src
  let index = 0
  if (imageFolder) {
    return text.replace(
      regExp,
      () => `<img src="${(config as any).apiUrl}/common/file/${imageFolder}/${imagesSrc[index++]}">`
    ) // replace image uuid with image url path
  } else {
    const imagesUuid = imagesSrc.map(src => src.split('/').slice(-1)[0]) // get uuid from src
    return text.replace(regExp, () => `<img src="${imagesUuid[index++]}">`) // replace image src with image uuid
  }
}
